import { Text } from 'components/typography';
import { format, parse } from 'date-fns';
import InfoIcon from 'icons/InfoIcon';
import { useCallback } from 'react';

import useTooltipControls from 'lib/hooks/useTooltipControls';
import type TalkMetaProps from './TalkMeta.props';
import ViewInfoTooltip from './ViewsInfoTooltip';

const TalkMeta = ({
  views,
  presenters,
  context,
  recordedOn
}: TalkMetaProps) => {
  const formatter = new Intl.NumberFormat('en-US');
  const { hideTooltip, toggleToolTip, visibleTooltip } =
    useTooltipControls(true);

  const getReleaseDate = useCallback(() => {
    if (recordedOn) {
      const parsedDate = parse(recordedOn, 'yyyy-MM-dd', new Date());
      const formattedDate = format(parsedDate, 'MMMM yyyy');
      return formattedDate;
    }
  }, [recordedOn]);

  const renderViewCount = () => {
    if (views >= 10_000) {
      const numberOfViews = formatter.format(views);
      return (
        <div className="mr-1 flex items-center gap-1">
          {numberOfViews} plays
          <ViewInfoTooltip
            isOpen={visibleTooltip !== null}
            onHide={hideTooltip}
          >
            <button
              type="button"
              id="info-button-talk-page"
              onClick={() => toggleToolTip('talk-view-count')}
            >
              <InfoIcon className="mb-[0.5px] h-4" />
            </button>
          </ViewInfoTooltip>
          |
        </div>
      );
    }
    return null;
  };

  const talkPresenter = `${presenters} `;
  const talkContext = ` ${context} `;
  const releaseDate = recordedOn ? ` • ${getReleaseDate()}` : ``;

  return (
    <div className="flex flex-1 flex-wrap items-center overflow-hidden">
      <Text size="m" className="text-gray-900" data-testid="talk-view-count">
        {renderViewCount()}
      </Text>
      <Text
        size="m"
        className="mr-1 text-gray-900"
        data-testid="talk-presenter"
      >
        {talkPresenter} |
      </Text>
      <Text size="m" className="mr-1 text-gray-900" data-testid="talk-context">
        {talkContext}
      </Text>
      <Text size="m" className="text-gray-900" data-testid="talk-release-date">
        {releaseDate}
      </Text>
    </div>
  );
};

export default TalkMeta;
