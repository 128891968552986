import cx from 'classnames';
import useVideoPlayerStore from 'components/video-player/store';
import { FormattedMessage } from 'react-intl';

import ToggleTranscriptButtonProps from './ToggleTranscriptButton.props';

const ToggleTranscriptButton = ({
  hasTranslations,
  isFullWidth
}: ToggleTranscriptButtonProps) => {
  const { isTranscriptVisible, onTranscriptToggle } = useVideoPlayerStore(
    state => ({
      isTranscriptVisible: state.isTranscriptVisible,
      onTranscriptToggle: state.onTranscriptToggle
    })
  );

  if (!hasTranslations) {
    return null;
  }

  // TODO: Will need to investigate on mobile devices, on button click, the button renders with
  // hover:bg-gray-900 and does not transition to bg-black until scroll event is triggered. https://tedconferences.atlassian.net/browse/WEB-409
  return (
    <>
      <button
        id="transcript-control"
        className={cx(
          'items-center justify-center whitespace-nowrap rounded-sm border-thin border-solid border-black px-4 py-3 text-tui-xs font-bold lg:my-0',
          {
            'bg-black text-white': isTranscriptVisible,
            'w-full': isFullWidth
          }
        )}
        type="button"
        onClick={() => onTranscriptToggle(!isTranscriptVisible)}
      >
        <FormattedMessage defaultMessage="Read transcript" />
      </button>
    </>
  );
};

export default ToggleTranscriptButton;
