import { useEffect } from 'react';

import { useTalkDetailsLazyQuery } from 'api';

import Divider from 'components/divider';
import useVideoPlayerStore from 'components/video-player/store';
import ToggleTranscriptButton from '../ToggleTranscriptButton';
import TalkActions from './TalkActions';
import TalkDescription from './TalkDescription';
import type TalkDetailsProps from './TalkDetails.props';
import TalkDetailsSkeleton from './TalkDetailsSkeleton';
import TalkMeta from './TalkMeta';
import TalkTitle from './TalkTitle';
import TalkTitleSkeleton from './TalkTitleSkeleton';

/** Render Meta details (views, record date) actions (like, share, add) */
const TalkDetails = ({
  video,
  displayCommentsButton,
  actionsRef,
  shortenedUrl
}: TalkDetailsProps) => {
  const [getTalkDetails, { data: talkDetails, loading }] =
    useTalkDetailsLazyQuery({
      fetchPolicy: 'cache-and-network'
    });

  const { transcriptLanguage, isTranscriptVisible } = useVideoPlayerStore(
    state => ({
      transcriptLanguage: state.transcriptLanguage,
      isTranscriptVisible: state.isTranscriptVisible
    })
  );

  const {
    id,
    slug,
    description,
    featured,
    presenterDisplayName,
    title,
    topics,
    type,
    videoContext,
    viewedCount,
    recordedOn,
    hasTranslations
  } = video;

  const hasTakeaways = video.takeaways?.nodes?.length > 0;

  const renderVideoContext = (videoContext: string, typeId: string): string => {
    if (typeId === '8') {
      return 'Partner Content';
    }
    if (typeId === '4' || typeId === '7') {
      return videoContext.replace('Salon', 'Salon:');
    }

    return videoContext;
  };

  const context = renderVideoContext(videoContext, type.id);

  useEffect(() => {
    if (transcriptLanguage) {
      getTalkDetails({
        variables: {
          id: slug,
          language: transcriptLanguage
        }
      });
    }
  }, [getTalkDetails, slug, transcriptLanguage]);

  return (
    <div className="flex w-full flex-col md:mb-4">
      <div ref={actionsRef} className="flex w-full flex-col justify-between">
        {isTranscriptVisible && loading ? (
          <TalkTitleSkeleton />
        ) : (
          <TalkTitle title={talkDetails?.video?.title || title} />
        )}
        <TalkMeta
          views={viewedCount}
          presenters={presenterDisplayName}
          context={context}
          recordedOn={recordedOn}
        />
        <Divider className="mb-2 mt-3 w-full" />
        <div className="flex w-full flex-col gap-3 md:mb-4 md:flex-row md:items-center md:justify-between">
          <TalkActions
            hasComments={displayCommentsButton}
            id={id}
            slug={slug}
            title={title}
            presenters={presenterDisplayName}
            context={context}
            viewedCount={viewedCount}
            shortenedUrl={shortenedUrl}
            showTakeaways={hasTakeaways && hasTranslations}
          />
          <ToggleTranscriptButton hasTranslations={hasTranslations} />
        </div>
      </div>
      <div className="flex">
        <div className="mr-2 flex w-full lg:flex-col">
          {isTranscriptVisible && loading ? (
            <TalkDetailsSkeleton />
          ) : (
            <div className="hidden lg:block">
              <TalkDescription
                description={talkDetails?.video?.description || description}
                featured={featured}
                topics={topics.nodes}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TalkDetails;
