import { useEffect, useRef, useState } from 'react';

import { Video } from 'api';

import { ScrollArrows } from 'components/@tedui';

import { AddToList } from './AddToList';
import { Comment } from './Comment';
import { Like } from './Like';
import Share from './Share';
import { Takeaways } from './Takeaways';
type TalkActionsProps = {
  id: string;
  slug: string;
  title: string;
  presenters: Video['presenterDisplayName'];
  context: string;
  viewedCount: number;
  hasComments: boolean;
  shortenedUrl: string;
  showTakeaways: boolean;
};

const TalkActions = ({
  id,
  slug,
  title,
  presenters,
  context,
  hasComments,
  shortenedUrl,
  showTakeaways
}: TalkActionsProps) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLButtonElement>(null);

  const [showArrows, setShowArrows] = useState(false);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const checkOverflow = () => {
    if (scrollAreaRef.current) {
      setShowArrows(
        scrollAreaRef.current.offsetWidth < scrollAreaRef.current.scrollWidth
      );
    }
  };

  useEffect(() => {
    if (!scrollAreaRef.current) return;

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [showTakeaways]);

  return (
    <div
      ref={scrollAreaRef}
      className="flex overflow-x-scroll scroll-smooth scrollbar-hide md:mb-0 md:overflow-x-hidden"
    >
      <ScrollArrows
        scrollAreaRef={scrollAreaRef}
        showArrows={showArrows}
        adjustArrowButtonPosition
      />
      <div className="relative flex items-center gap-3 md:gap-2">
        {showTakeaways && <Takeaways />}
        <Share
          ref={leftRef}
          slug={slug}
          title={title}
          presenters={presenters}
          context={context}
          shortenedUrl={shortenedUrl}
        />
        <AddToList id={id} />
        <Like id={id} />
        {hasComments && <Comment id={id} ref={rightRef} />}
      </div>
    </div>
  );
};

export default TalkActions;
