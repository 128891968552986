import cx from 'classnames';

import { Text } from 'components/@tedui';

import type TalkDescriptionProps from './TalkDescription.props';
import TopicsList from '../../TopicsList';
import TalkTedWorkCTA from '../TalkTedWorkCTA';
import { isEmpty } from 'rambda';

const TalkDescription = ({ description, topics }: TalkDescriptionProps) => {
  const hasTopics = !isEmpty(topics);

  return (
    <div className="flex w-full flex-col" data-testid="talk-description">
      <div
        className={cx({
          'mb-4': hasTopics
        })}
      >
        <Text variant="body2" testId="talk-description-text">
          {description}
        </Text>
      </div>
      <div className="mb-3 hidden lg:block">
        <TalkTedWorkCTA />
      </div>
      {hasTopics && <TopicsList topics={topics} />}
    </div>
  );
};

export default TalkDescription;
