// @ts-strict-ignore
import { ButtonTooltip } from 'components/@tedui/ButtonTooltip';
import { TalkActionButton } from 'components/pages/talks/TalkActionButton';
import useVideoPlayerStore from 'components/video-player/store';
import {
  AuthRedirectType,
  createSignInRedirectUrl,
  useAuthenticated
} from 'lib/auth';
import useTooltipControls from 'lib/hooks/useTooltipControls';
import { isEqual } from 'lodash-es';
import { memo, useCallback, useMemo } from 'react';
import { SignInTooltip } from '../SignInTooltip';

type CommentProps = {
  id: string;
};

const Comment = ({
  ref,
  ...id
}: CommentProps & {
  ref: React.RefObject<HTMLButtonElement | null>;
}) => {
  const { hideTooltip, toggleToolTip, visibleTooltip } = useTooltipControls();
  const isLoggedIn = useAuthenticated();
  const { onAddComment } = useVideoPlayerStore(state => ({
    onAddComment: state.onAddComment
  }));

  const scrollToComments = useCallback(() => {
    const commentsSection = document.getElementById('comments');
    commentsSection.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const redirectURL = useMemo(
    () =>
      createSignInRedirectUrl(AuthRedirectType.Comment, {
        id
      }),
    [id]
  );

  const handleClick = useCallback(() => {
    onAddComment();

    if (!isLoggedIn) {
      toggleToolTip('Comment');
      return;
    }

    scrollToComments();
  }, [onAddComment, isLoggedIn, scrollToComments, toggleToolTip]);

  return (
    <ButtonTooltip
      isVisible={visibleTooltip === 'Comment'}
      onHide={hideTooltip}
      renderedTooltip={
        <SignInTooltip
          actionConclusionMessage="to leave a comment"
          redirectURL={redirectURL}
        />
      }
      context="Comments"
    >
      <TalkActionButton
        ref={ref}
        testId="CommentsActionButton_TESTID"
        iconName="message-square"
        onClick={handleClick}
        label="Comment"
      />
    </ButtonTooltip>
  );
};

export default memo(Comment, isEqual);
