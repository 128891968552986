import type { JSX } from 'react';

type InfoIconProps = {
  className?: string;
};

const InfoIcon = (props: InfoIconProps): JSX.Element => {
  const { className } = props;
  return (
    <svg
      className={className || ''}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 15.1673C11.6821 15.1673 14.6668 12.1825 14.6668 8.50065C14.6668 4.81875 11.6821 1.83398 8.00016 1.83398C4.31826 1.83398 1.3335 4.81875 1.3335 8.50065C1.3335 12.1825 4.31826 15.1673 8.00016 15.1673Z"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.1667V8.5"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.83398H8.00667"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default InfoIcon;
