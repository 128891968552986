import { Text } from 'components/@tedui';

import TalkTitleProps from './TalkTitle.props';

const TalkTitle = ({ title }: TalkTitleProps) => {
  return (
    <div id="talk-title" className="mb-1 mt-2 flex w-full flex-col">
      <Text
        tag="h1"
        variant="header2"
        data-testid="talk-title"
        UNSAFE_className="mr-5"
      >
        {title}
      </Text>
    </div>
  );
};

export default TalkTitle;
