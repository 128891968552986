import Loader from 'components/loaders';

export default function TalkTitleSkeleton(): React.ReactNode {
  return (
    <>
      <div className="mb-1 mt-2 block xl:hidden">
        <Loader.Size
          height="1.25rem"
          width="100%"
          color="rgb(18 18 18 / 12%)"
        />
      </div>
      <div className="mb-2">
        <Loader.Size height="1.25rem" width="90%" color="rgb(18 18 18 / 12%)" />
      </div>
    </>
  );
}
