import Loader from 'components/loaders';

export default function TalkDetailsSkeleton(): React.ReactNode {
  return (
    <>
      <div className="hidden lg:block">
        <div>
          <div className="mb-1">
            <Loader.Size
              height="1rem"
              width="100%"
              color="rgb(18 18 18 / 12%)"
            />
          </div>
          <div className="mb-1">
            <Loader.Size
              height="1rem"
              width="100%"
              color="rgb(18 18 18 / 12%)"
            />
          </div>
          <div className="mb-1">
            <Loader.Size
              height="1rem"
              width="100%"
              color="rgb(18 18 18 / 12%)"
            />
          </div>
          <div className="mb-3">
            <Loader.Size
              height="1rem"
              width="100%"
              color="rgb(18 18 18 / 12%)"
            />
          </div>
        </div>
      </div>
    </>
  );
}
