import { Text } from 'components/@tedui';
import {
  TooltipContent,
  TooltipRoot,
  TooltipTrigger
} from 'components/shared/Tooltip';
import Arrow from 'icons/TooltipArrowIcon';
import type { ReactElement } from 'react';

type ViewInfoTooltipProps = {
  isOpen?: boolean;
  children: ReactElement;
  onHide: () => void;
};

const ViewInfoTooltip = ({
  isOpen = false,
  children,
  onHide
}: ViewInfoTooltipProps): React.ReactNode => {
  const tooltipText =
    'This number includes plays and downloads on TED.com, the TED app, YouTube and podcasts.';

  return (
    <TooltipRoot open={isOpen} onOpenChange={open => !open && onHide()}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        side="bottom"
        align="start"
        sideOffset={15}
        alignOffset={-32}
        avoidCollisions={false}
        className="rounded-sm bg-[#00105B] bg-transparent p-0 shadow-none"
      >
        <Arrow variant="blue" className="absolute -top-3 left-7" />
        <div className="relative overflow-visible">
          <div className="relative w-full max-w-64 rounded-sm bg-[#00105B] px-2 pb-3 pt-2">
            <Text
              tag="p"
              useNewTextStyles
              variant="body2"
              UNSAFE_className="text-white mb-2"
            >
              {tooltipText}
            </Text>

            <button
              type="button"
              style={{ zIndex: 99999 }}
              className="rounded-sm bg-white px-3 py-1"
              onClick={onHide}
            >
              <Text
                tag="p"
                useNewTextStyles
                variant="caption1"
                weight="font-semibold"
                UNSAFE_className="text-black"
              >
                Got it
              </Text>
            </button>
          </div>
        </div>
      </TooltipContent>
    </TooltipRoot>
  );
};

export default ViewInfoTooltip;
