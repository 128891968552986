import { TalkActionButton } from 'components/pages/talks/TalkActionButton';
import { isEqual } from 'lodash-es';
import { memo, useCallback } from 'react';
import useVideoPlayerStore from '../../../../../../video-player/store';
import { useModalStore } from '../../../Summary/store';

const Takeaways = () => {
  const { openModalSummary, closeAllModal, isModalSummaryOpen } =
    useModalStore();
  const { onTakeawaysEntry } = useVideoPlayerStore(state => ({
    onTakeawaysEntry: state.onTakeawaysEntry
  }));

  const handleClick = useCallback(() => {
    if (isModalSummaryOpen) {
      closeAllModal();
    }

    if (!isModalSummaryOpen) {
      onTakeawaysEntry();
      openModalSummary();
    }
  }, [openModalSummary, isModalSummaryOpen, closeAllModal, onTakeawaysEntry]);

  return (
    <TalkActionButton
      testId="TakeawaysActionButton_TESTID"
      iconName="takeaways"
      onClick={handleClick}
      label="Takeaways"
      newIcon
    />
  );
};

export default memo(Takeaways, isEqual);
